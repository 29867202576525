.App {
  margin-top: 20px;
}

.App-header {
  /** background:url(./images/ABX-Home-page-Slider.png) no-repeat center center fixed; **/
  background-size: cover;

  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /** align-items: center; */
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  
  font-family: Roboto;
  font-weight: 400;
  /** letter-spacing: 0px; */
  font-style: normal;
  /** line-height: 1; */
}

#title-header1 {
    color: white;
    background-color: #000000;
    background:url(./images/ABX-Home-page-Slider.png);
    background-position : 100% 100%;
}

p  {
  text-align: left;
  margin: 5px 20px 5px 20px !important; 
} 

h1, h2, h5, #digitalid-verify {
  text-align:center;
}

h5 {
  margin-bottom: 5px;
}

#digitalid-verify > button {
  width: 364px !important;
  height: 65px !important;
}

#abelogo > img {
  height: 5vmax;
  width: 10vmax;  
  display:block;
  margin: auto !important;
}

#austpost > img {
    height: 10vmax;
    width: 20vmax;
    display:block;
    margin: auto !important;
}

#instruct1, #instruct2 {
  width:80%;
  margin: auto;
}


/** Remove space between Top Title and Sub Title underneath*/
h1 {
  margin-bottom: 0px;
}
h2 {
  margin-top:0px;
}

/** Firefox specific CSS **/
@-moz-document url-prefix() {
  .ff {
      margin-top: 30px;
  }
}
